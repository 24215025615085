import db from '@/db'
import firebase from '@/firebase'

import {
    //SOMETHING
  } from './mutations'
  
  const state = {
    

    
  }
  
  const getters = {
    //currentCategory: ({currentCategory}) => currentCategory,

    
  }
  
  const mutations = {

    /*
    [SET_CATEGORY_LOADING] (state, bool) {
      state.loading = bool
    },
    [SET_CATEGORY] (state, payload) {
      state.newCategory.dateCompleted = payload.dateCompleted
      state.newCategory.dateCreated  = payload.dateCreated
      state.newCategory.dateUpdated  = payload.dateUpdated
      state.newCategory.experienceState  = payload.experienceState
      state.newCategory.meanCategory = payload.meanCategory
      state.newCategory.momentId  = payload.momentId
      state.newCategory.categoryStatement  = payload.categoryStatement
      state.newCategory.categoryStimulusQuestion  = payload.categoryStimulusQuestion
      state.newCategory.categoryTheme  = payload.categoryTheme
    },
    [RESET_CATEGORY] (state) {
      state.newCategory.dateCompleted = null
      state.newCategory.dateCreated  = null
      state.newCategory.dateUpdated  = null
      state.newCategory.experienceState  = null
      state.newCategory.meanCategory = null
      state.newCategory.momentId  = null
      state.newCategory.categoryStatement  = null
      state.newCategory.categoryStimulusQuestion  = null
      state.newCategory.categoryTheme  = null
    },
    [PUSH_CATEGORY] (state, payload) {
      state.categories.push(payload.category)
    },
    [REMOVE_CATEGORY] (state, payload) {
      if (state.categories.length >= 1) {
        state.categories.splice(payload.itemIndex, 1)
      }
    },
    [RESET_CATEGORY_LIST] (state) {
        state.categories = []
    } */
  }
  

  
  const actions = {

    async sendWelcomeNotification (_,user) {
        return new Promise((resolve) => {
            (async () => {
              var batchRef = await db.collection('batches').doc()
              var newTaskRef = await db.collection('tasks').doc()
              var batchId = batchRef.id
              var dateNow = Date.now();
              var taskCount = 0;

              //get user's timezone
              var userRef = await db.collection('users').doc(user.uid).get()
              await newTaskRef.set({
                  'taskId': newTaskRef.id,
                  'batchId': batchId,
                  'time': dateNow,
                  'worker': 'sendWelcomeNotification',
                  'userUid': user.uid,
                  'userTimezone': userRef.data().userTimezone
              })

              taskCount++
              batchRef.set({
                  'batchId': batchId,
                  'batchCount': taskCount,
                  'batchType': 'sendWelcomeNotification',
                  'batchCreationTime': dateNow,
                  'dateCreated': new Date()
              });


              resolve()
            })()
        })
    },


    async sendHeedarNotification (_, payload) {
      return new Promise((resolve, reject) => {
          firebase.auth().onAuthStateChanged(async (user) => {  
              if (user) { 

                  //get batch Id
                  var batchRef = await db.collection('batches').doc()
                  var newTaskRef = await db.collection('tasks').doc()
                  var batchId = batchRef.id
                  var dateNow = Date.now();
                  var taskCount = 0;

                  //get user's timezone
                  var userRef = await db.collection('users').doc(user.uid).get()
                  //update agendaItem
                  var getAgendaItem = await db.collection('users').doc(user.uid).collection('dailyAgendaReports').doc(payload.dayStamp).get()

                  if(getAgendaItem.data().agendaItems[payload.agendaItemId].agendaItemNotificationStatus != 'sent') {    //If server already sent, then don't send
                    //create task
                    await newTaskRef.set({
                        'taskId': newTaskRef.id,
                        'title': payload.title,
                        'startTime': payload.startTime,
                        'batchId': batchId,
                        'time': dateNow,
                        'worker': 'sendHeedarNotification',
                        'userUid': user.uid,
                        'userTimezone': userRef.data().userTimezone
                    })

                    //create batch
                    taskCount++
                    batchRef.set({
                        'batchId': batchId,
                        'batchCount': taskCount,
                        'batchType': 'sendHeedarNotification',
                        'batchCreationTime': dateNow,
                        'dateCreated': new Date()
                    });

                    //update agendaItem
                    var updateAgendaItem = await db.collection('users').doc(user.uid).collection('dailyAgendaReports').doc(payload.dayStamp)

                    updateAgendaItem.set({
                      'agendaItems': {
                        [payload.agendaItemId]: {
                          'agendaItemNotificationStatus': 'sent'
                        }
                      }
                    }, {merge:true})

                  }

                
                  resolve()
              } else {
                /* eslint-disable no-console */
                  console.log("no user")
                  reject()
              }
          })
      })
  },


    /*
    async getValidCategory ({commit}, payload) {
      return new Promise(async function(resolve, reject) {
        var response = null
        await db.collection('taxonomies')
                .doc('v1_2020_taxonomy')
                .collection('intent_mean_categories')
                .doc(payload)
                .get()
                .then((doc) => {
                  if(doc.exists) {
                    response = doc.data()
                    //console.log("response from getValidCategory()", response)

                  }
                })
        resolve(response)
      })
    },
    async getParentCategoryData ({}, payload) {
      return new Promise(async function(resolve, reject) {
        var response = null
        await db.collection('taxonomies')
                .doc('v1_2020_taxonomy')
                .collection('intent_mean_parent_categories')
                .doc(payload)
                .get()
                .then((doc) => {
                  if(doc.exists) {
                    response = doc.data()
                    console.log("response from getParentCategory()", response)
                  }
                })
        resolve(response)
      })
    },
    */
    
    

    
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }