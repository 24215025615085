<template>
  <div class="signin">
    <br />
    <center>
      <div class="container">
        <div v-if="((profile.accountType === 'heediUser') || (profile.accountType === null))" class="googleSignin">

          <div class="signInHeediPanel">
            <div class="panelTitle">heedi account (Google Sign-in)</div>
            <img @click="doLogin" src="@/assets/signin_heedi.png" alt="Sign-in with a heedi account (Google Signin)."><br /><br />
          </div>

          <!--
          <div id="signinLabel">
            Sign in with:<br /> 
            <img @click="doLogin" id="signinImg" src="@/assets/google_login_logo.png" alt="Sign-in with Google">
          </div>
           -->
        </div>

        
        <div v-if="((profile.accountType === 'anonymous') || (profile.accountType === null))" id="anonymousSignin">

          <div class="signInAnonymousPanel">
            <div class="panelTitle">anonymous account</div>
            <img @click="doAnonymousLogin" src="@/assets/signin_anonymous.png" alt="Sign-in with an anonymous account."><br /><br />
            
          </div>

          <!--<a href="#" @click="doAnonymousLogin">Sign in Anonymously</a>-->

        </div>


        
        
  
        
      </div>
    </center>
  </div>
</template>

<script>
  //import '@/plugins/vuetify'
  import signInModule from '@/store/signin'
  import userModule from '@/store/user'
  import settingsModule from '@/store/settings'
  import feedbackModule from '@/store/feedback'
  import experienceModule from '@/store/experience'
  import notificationModule from '@/store/notification'
  import { mapGetters, mapActions } from 'vuex'
  //import router from '@/router'
  //import firebase from '@/firebase'
  //import store from '@/store'

  export default {
    name: "sign-in",
    components: {

    },
    data () {
      return {
        
        
      }
    },
    computed: {
      ...mapGetters('auth', [
        'profile',
        'role',
        'loggedIn',
        'hasMinders',
        'hasInitializedConsole'
      ]),
    },
    methods: {
      ...mapActions('signin', {
        googleLogin: 'googleLogin',
        anonymousLogin: 'anonymousLogin'
      }),
      ...mapActions('auth', [
        'setAnonymousProfile'
      ]),
      ...mapActions('user', [
        'createNewUser',
        'logSignInTime',
        'getUserState',
        'checkIfUserExists'
      ]),
      ...mapActions('search', [
        'setShowSearch'
      ]),
      
      doLogin () {
        //console.log("doLogin called: route:  "+this.$route.query.to)
        if(this.$route.query.to) {
          this.googleLogin(this.$route.query.to).then((route)=>{
            //console.log("going to the route: "+route)
            this.$router.push(route)
          })
        }
        else {
          this.googleLogin(null).then(()=>{
            if(this.hasInitializedConsole) {
              this.$router.push('/console')
            } else {
              this.$router.push('/home')
            }
          })
        }
      },
      async doAnonymousLogin () {
        //console.log("doAnonymousLogin called: route:  "+this.$route.query.to)
        if(this.$route.query.to) {

          this.anonymousLogin().then(() => {
            this.$router.push(this.$route.query.to)
          })

        }
        else {
          this.anonymousLogin().then(() => {
            if(this.hasInitializedConsole) {
              this.$router.push('/console')
            } else {
              this.$router.push('/home')
            }
            
          })
        }

        //ALL THE CODE WORKS BELOW, DELICATE
        /* firebase.auth().signInAnonymously().catch((error) => {   
          var errorMessage = error.message;
          console.log("anonymous login errors: "+error.message)
        });
        firebase.auth().onAuthStateChanged(async (user) => {  
          if(user) {
            var bool = await this.checkIfUserExists(user.uid)
            if(bool === false) {
              await this.createNewUser(user)
              await this.getUserState(user.uid)
            }else {
              await this.getUserState(user.uid)
            }
            await this.setAnonymousProfile(user)
            this.logSignInTime(user.uid)
            this.$router.push('/console')
          }
        }) */
        //ALL THE CODE WORKS ABOVE; WARNING VERY DELICATE
      },
      something () {

      }

    },
    beforeCreate () {
      if(!this.$store.state.signin) this.$store.registerModule('signin', signInModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.feedback) this.$store.registerModule('feedback', feedbackModule)
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      if(!this.$store.state.notification) this.$store.registerModule('notification', notificationModule)
      
      
      
      
    },
    created () {
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/signin'});
      
    },
    mounted () {
      this.setShowSearch(false)


      window.addEventListener("load", () => {
        var str = this.$route.query.to
        if(str !== undefined) {
          if(str.indexOf('check-in') > -1) {
            //console.log("passed the regex test")
            this.googleLogin(this.$route.query.to).then((route)=>{
              //console.log("going to the route: "+route)
              this.$router.push(route)
            })
          }
        }
      })

      

    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>
  .container {
    /*display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-gap: 8px;
    max-width: 140px;
    margin: 0 auto 8px;*/

    margin: 0 auto;
    max-width: 280px;
  }
  .panelTitle {
    margin-bottom: 10px;
    font-size: 1.3em;
  }
  .signInHeediPanel {
    box-sizing: border-box;
  }
  .signInAnonymousPanel {
    box-sizing: border-box;
  }
  img {
    width: 100%;
    object-fit: cover;
  }


  @media screen and (min-width: 375px) {
    /*.container {
      grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
      grid-gap: 10px;
      max-width: 190px;
    }*/
  }


  @media screen and (min-width: 700px) {
    /*.container {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      grid-gap: 30px;
      max-width: 600px;
    }*/
  }
  
  @media screen and (min-width: 1000px) {
    /*.container {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      grid-gap: 30px;
      max-width: 800px;
    }*/
  }


</style>
